

// Packages
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';

// Stores
import { usePreloaderControllerStore } from '@white-label-store/preloader-controller';

import { DOMAINS_NAMES, getDomainUrl } from '@white-label-helper/switch-app';

export default defineComponent({
  name: 'ErrorLayout',

  components: {
    ErrorPage: () => import('@white-label-frontend/error'),
  },

  // Remove preloader for avoid wrong preloader on this page
  created() {
    this.commitUpdatePreloader('');
  },

  mounted() {
    // TODO Need to delete when dev will moved to hosting, which supports Nuxt middleware
    this.browserSupportCheck();
  },
  methods: {
    ...mapActions(usePreloaderControllerStore, {
      commitUpdatePreloader: 'updatePreloader',
    }),
    goBack() {
      const ecommerceUrl = getDomainUrl(DOMAINS_NAMES.ECOMMERCE);

      if (ecommerceUrl) {
        window.location.replace(ecommerceUrl);
      }
    },
  },
});
